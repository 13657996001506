import React from 'react';
import ReactDOM from 'react-dom';
import {Registration} from './pages/Registration';
import {ThemeProvider} from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import './Registration.css';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'GothamRoundedBook'
    }
});

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <Registration />
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
