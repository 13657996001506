import React, {useState} from 'react';
import styled from 'styled-components';

import FormControl from '@material-ui/core/FormControl';
import {Theme, withStyles} from '@material-ui/core/styles';
import {ExpandMore as DropDownArrow} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import {AttachCV, EncodedFile} from './AttachCV';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {InputBaseComponentProps} from '@material-ui/core/InputBase';

const FieldWrapper = withStyles((theme: Theme) => ({
    root: {
        margin: theme.spacing(1),
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyItems: 'flex-start'
    }
}))(FormControl);

const StyledButton = withStyles((_: Theme) => ({
    root: {
        borderRadius: 30,
        textTransform: 'none',
        backgroundColor: '#d4d745',
        fontSize: '14pt',
        fontFamily: 'GothamRoundedBold',
        '&:hover': {
            backgroundColor: '#8f912f'
        },
        '& > *': {
            fontFamily: 'GothamRoundedBold'
        }
    }
}))(Button);

const InputButton = () => {
    return (
        <StyledButton disableRipple>
            Start your registration
        </StyledButton>
    );
};

export const SubmitButton = styled(InputButton)`
  border-radius: 50%;
`;

type CommitOnlyInputFieldProps<T> = {
    onCommit(t: T): void;
};

type InputFieldProps<T> = CommitOnlyInputFieldProps<T> & {
    value: T;
};

type TextInputFieldProps<T> = InputFieldProps<T> & {
    id: string;
    required?: boolean;
    caption: string;
    type: 'text' | 'email' | 'tel';
    helperText: string;
    rows?: number;
};

type SelectOption<T> = {
    value: T;
    label: string;
};

type SelectInputFieldProps<T> = InputFieldProps<T> & {
    id: string;
    caption: string;
    options: SelectOption<T>[];
};

function TextInputField<T>({value, onCommit, id, type, caption, rows}: TextInputFieldProps<T>) {
    const [getValue, setValue] = useState<T>(value);
    const rowCount = rows !== undefined ? rows : 1;
    const rowsMax = 2 * rowCount - 1;
    const labelId = `${id}-label`;

    const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as T);
    };

    const handleCommit = (_event: any) => {
        onCommit(getValue);
    };

    return (
        <FieldWrapper variant='outlined'>
            <InputLabel id={labelId} htmlFor={id}>{caption}</InputLabel>
            <OutlinedInput
                id={id}
                label={caption}
                value={getValue}
                type={type}
                inputMode={type}
                fullWidth={true}
                rows={rowCount}
                rowsMax={rowsMax}
                multiline={rowCount > 1}
                onChange={handleValueChange}
                onBlur={handleCommit}
            />
        </FieldWrapper>
    );
}

function SelectField<T extends string | undefined>({value, onCommit, id, caption, options}: SelectInputFieldProps<T>) {
    const labelId = `${id}-label`;

    const handleCommit = (event: React.ChangeEvent<{ value: unknown }>) => {
        onCommit(event.target.value as T);
    };

    return (
        <FieldWrapper variant='outlined'>
            <InputLabel id={labelId} htmlFor={id}>{caption}</InputLabel>
            <Select
                native
                id={id}
                label={caption}
                value={value}
                fullWidth={true}
                onChange={handleCommit}
                IconComponent={DropDownArrow}
            >
                <option aria-label='none' value=''/>
                {options.map((o, i) => {
                    let v: string = o.value as string
                    return (<option value={v} key={i}>{o.label}</option>);
                })}
            </Select>
        </FieldWrapper>
    );
}

const AttachCvAdapter = ({cv, setCv}: InputBaseComponentProps) =>
    <AttachCV cv={cv} setCv={setCv} />

export const CVField = ({value, onCommit}: InputFieldProps<EncodedFile | undefined>) => {
    const labelText = 'CV';
    const id = 'cv-input';
    const labelId = `${id}-label`;

    return (
        <FieldWrapper variant='outlined'>
            <InputLabel id={labelId} htmlFor={id}>{labelText}</InputLabel>
            <OutlinedInput
                inputComponent={AttachCvAdapter}
                inputProps={{
                    cv: value,
                    setCv: onCommit
                }}
                label={labelText}
                value={value ? value.name : ''}
                type='text'
                fullWidth={true}
            />
        </FieldWrapper>
    );
};

export const NameField = ({value, onCommit}: InputFieldProps<string>) =>
    <TextInputField
        value={value}
        required={true}
        onCommit={onCommit}
        id='registrant-name'
        type='text'
        helperText='e.g. John Smith'
        caption='Name'
    />

export const EmailAddressField = ({value, onCommit}: InputFieldProps<string>) =>
    <TextInputField
        value={value}
        required={true}
        onCommit={onCommit}
        id='registrant-email-address'
        type='email'
        helperText='e.g. johnsmith@my-email-provider.example'
        caption='Email address'
    />

export const TelephoneNumberField = ({value, onCommit}: InputFieldProps<string>) =>
    <TextInputField
        value={value}
        onCommit={onCommit}
        id='phone-number'
        type='tel'
        helperText='e.g. 07890123456, +61401234567'
        caption='Phone number'
    />

export type PositionPreference = 'T' | 'TA' | 'NN' | undefined;
const positionPreferenceList: SelectOption<PositionPreference>[] = [
    { value: 'T', label: 'Teaching Jobs' },
    { value: 'TA', label: 'Teaching Assistant Jobs' },
    { value: 'NN', label: 'Nursery Nurse Jobs' }
];


export type JobPreference = 'EY' | 'Pri' | 'Sec' | 'SEN' | undefined;
const jobPreferenceList: SelectOption<JobPreference>[] = [
    { value: 'EY', label: 'Early Years School Jobs' },
    { value: 'Pri', label: 'Primary School Jobs' },
    { value: 'Sec', label: 'Secondary School Jobs' },
    { value: 'SEN', label: 'SEND School Jobs' }
];

export const PositionPreferenceField = ({value, onCommit}: InputFieldProps<PositionPreference>) =>
    <SelectField
        value={value}
        onCommit={onCommit}
        id='position-preference'
        caption='Interested in'
        options={positionPreferenceList}
    />

export const JobPreferenceField = ({value, onCommit}: InputFieldProps<JobPreference>) =>
    <SelectField
        value={value}
        onCommit={onCommit}
        id='job-preference'
        caption='Job Preference'
        options={jobPreferenceList}
    />

export const MessageField = ({value, onCommit}: InputFieldProps<string>) =>
    <TextInputField
        value={value}
        onCommit={onCommit}
        id='message'
        caption='Your message'
        type='text'
        helperText='Talk to us!'
        rows={5}
    />
