import React from 'react';

import styled from 'styled-components';
import {DesktopWidthMin, FullWidthMin} from '../Breakpoints';

import logo from '../images/vibe-logo-white.svg';

const LogoWrapper = styled.div`
  @media screen and (max-width: ${DesktopWidthMin}px) {
    display: none;
  }

  @media screen and (max-width: ${FullWidthMin}px) {
    visibility: hidden;
  }

  @media screen and (min-width: ${FullWidthMin}px) {
    padding-left: 30px;
  }
`;

export const VibeVLogo = () => (
    <LogoWrapper>
        <img src={logo} alt='Vibe Logo' />
    </LogoWrapper>
);
