import React from 'react';

import styled from 'styled-components';
import {RegistrationForm} from '../components/RegistrationForm';
import {DesktopWidthMin, FullWidthMin} from '../Breakpoints';

import {VibeVLogo} from '../components/VibeVLogo';
import {VibeWordLogo} from '../components/VibeWordLogo';

const RegistrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// noinspection CssInvalidPropertyValue
const IntroSection = styled.div`
  @media (max-width: ${DesktopWidthMin}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(247,244,244);
  }

  @media (min-width: ${DesktopWidthMin}px) {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 0 calc(max(250px, 90% - 390px)) 390px;
    grid-column-gap: calc(max(30px, 3%));
    justify-content: flex-start;

    background-image: url('https://d2ry9vue95px0b.cloudfront.net/campaign/vibe/Vibe_hero.jpg');
    background-size: cover;
    background-position: center 60%;
  }
  
  @media (min-width: ${FullWidthMin}px) {
    grid-template-columns: max(60px, 25% - 200px) calc(min(78% - 390px, 640px)) 390px;
    grid-column-gap: 5%;

    background-position: center 65%;
  }
`;

const Intro = styled.div`
  display: block;

  @media (max-width: ${DesktopWidthMin}px) {
    padding: 20px 30px;

    background-image: url('https://d2ry9vue95px0b.cloudfront.net/campaign/vibe/Vibe_hero.jpg');
    background-size: cover;
    background-position: center;

    text-align: center;
    p {
      font-size: 16pt;
    }
  }
  
  @media (min-width: ${DesktopWidthMin}px) {
    p {
      max-width: 35rem;
      font-size: 20pt;
    }
  }
  
  @media screen and (min-width: ${FullWidthMin}px) {
  }

  h1 {
    font-size: 34pt;
    font-family: GothamRoundedBold, sans-serif;
  }
  
  p > strong {
    font-family: GothamRoundedBold, sans-serif;
    font-weight: normal;
  }

  color: #fff;
`;

export const Registration = () => (
    <RegistrationWrapper>
        <IntroSection>
            <VibeVLogo />
            <Intro>
                <VibeWordLogo />
                <h1>Something better</h1>
                <p>
                    Hi. Find the <strong>best teaching jobs</strong> with the #1 teaching agency!
                    We <strong>inspire better education</strong> by
                    matching great teachers to schools <strong>in London</strong>.
                </p>
                <p>
                    It's a <strong>smile</strong> difference!
                </p>
            </Intro>
            <RegistrationForm/>
        </IntroSection>
    </RegistrationWrapper>
);
