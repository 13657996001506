import React from 'react';

import styled from 'styled-components';

import logo from '../images/Vibe_wlogo.png';

const LogoImage = styled.img`
    width: 135px;
`;

export const VibeWordLogo = () => (
    <LogoImage src={logo} alt='Vibe' />
);
