import React, {useState} from 'react';
import styled from 'styled-components';

import {
    NameField,
    EmailAddressField,
    TelephoneNumberField,
    PositionPreferenceField,
    PositionPreference,
    JobPreference,
    JobPreferenceField,
    MessageField,
    SubmitButton,
    CVField
} from './RegistrationFormFields';
import {PrivacyPolicyLink} from './PrivacyPolicyLink';
import {DesktopWidthMin} from '../Breakpoints';
import { EncodedFile } from './AttachCV';

const RegistrationFormWrapper = styled.div`
  @media (max-width: ${DesktopWidthMin}px) {
    border: none;
    border-radius: 0;
  }
  @media (min-width: ${DesktopWidthMin}px) {
    border-radius: 12px;
  }

  width: calc(min(390px, 100vw));
  background-color: rgb(247, 244, 244);
  padding: 1ex 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormHeader = styled.h1`
  font-family: GothamRoundedBold, sans-serif;
  font-size: 23px;
  text-align: center;
  color: #1d3a72;
`;

const RegistrationCondition = styled.div`
  font-family: GothamRoundedBook, sans-serif;
  font-size: 11px;
  color: #6c6c6b;
  padding: 2ex 1rem;
`;

export const RegistrationForm = () => {
    const [getName, setName] = useState('');
    const [getEmailAddress, setEmailAddress] = useState('');
    const [getPhoneNumber, setPhoneNumber] = useState('');
    const [getPositionPreference, setPositionPreference] = useState<PositionPreference>();
    const [getJobPreference, setJobPreference] = useState<JobPreference>();
    const [getMessage, setMessage] = useState<string>('');
    const [getCv, setCv] = useState<EncodedFile>();

    return (
        <RegistrationFormWrapper>
            <FormHeader>Let's talk about your future!</FormHeader>
            <NameField value={getName} onCommit={setName}/>
            <EmailAddressField value={getEmailAddress} onCommit={setEmailAddress}/>
            <TelephoneNumberField value={getPhoneNumber} onCommit={setPhoneNumber}/>
            <PositionPreferenceField value={getPositionPreference} onCommit={setPositionPreference}/>
            <JobPreferenceField value={getJobPreference} onCommit={setJobPreference} />
            <CVField value={getCv} onCommit={setCv} />
            <MessageField value={getMessage} onCommit={setMessage} />
            <RegistrationCondition>
                By registering you agree to our <PrivacyPolicyLink/>
            </RegistrationCondition>
            <SubmitButton />
        </RegistrationFormWrapper>
    );
};
