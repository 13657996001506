import React, {FC} from 'react';

import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import {
    AttachFile as AttachCVIcon,
    Clear as DetachCVIcon
} from '@material-ui/icons';
import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core/styles';

import styled from 'styled-components';

const DetachButton = withStyles((_: Theme) => ({
    root: {
    }
}))(IconButton);

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        hiddenFileInput: {
            display: 'none'
        }
    })
);

export type EncodedFile = {
    name: string;
    base64Encoding: string;
    mimeType: string;
};

type AttachCVProps = {
    cv?: EncodedFile;
    setCv: (file: EncodedFile | undefined) => void;
}

const Detach = styled.div`
  width: 100%;
  height: 44px;
  padding-left: 12px;
  
  display: grid;
  grid-template-columns: auto 40px;
  grid-column-gap: 0;
  align-items: center;

  border: 1px solid #ccc;
  border-radius: 4px;
`;

const AttachCvWrapper = styled.div`
  width: 100%;
  margin: 10px 5px;
  
  display: flex;
  flex-direction: row;
  justify-content: center;

  z-index: 10;
  background-color: #fff;
  
  height: max-content;
`;

// const mimeTypes = [];

const AttachLabel = styled.label`
  width: 100%;
  height: 44px;
  padding-left: 8px;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  
  border: 1px solid #ccc;
  border-radius: 4px;
  
  &:hover {
    cursor: pointer;
    background: #e0f4e8f0;
    border: 1px solid #c0e0c0;
  }
`;

const CvName = styled.div`
  width: 100%;
  display: block;

  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  font-size: 14px;
`;

const LabelText = styled.div`
  display: block;

  vertical-align: middle;

  color: rgba(0, 0, 0, 0.54);
`;

export const AttachCV: FC<AttachCVProps> = ({cv, setCv}) => {
    const inputId = 'attach-cv-input';
    const acceptMimeTypes: string = [
        'text/plain',
        'application/pdf',
        'application/postscript',
        'application/vnd.apple.pages',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordpressingml.document',
        'image/png',
        'image/jpeg',
        '.rtf',
        '.txt',
        '.pdf',
        '.doc',
        '.docx',
        '.ps'
    ].join(',');
    const classes = useStyles();

    const onCvLoadSuccess = (name: string, mimeType: string) => (pe: ProgressEvent) => {
        let reader = pe.target as FileReader;
        let content = reader.result as string;
        let base64Encoding = btoa(content);
        console.log(`Loaded CV: ${name}`);
        console.log(`Progress event: ${pe.loaded}`)
        setCv({ name, base64Encoding, mimeType });
    };

    const onCvLoadStart = (filename: string) => (_: ProgressEvent) => {
        console.log(`Loading file: ${filename}`);
    };

    const maybeSetCv = (files: FileList | null) => {
        if (files && files.length > 0) {
            let file = files[0];
            let reader = new FileReader();
            reader.onload = onCvLoadSuccess(file.name, file.type);
            reader.onloadstart = onCvLoadStart(file.name);
            reader.readAsBinaryString(file);
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target?.files;
        maybeSetCv(files);
    };

    const handleCvDetach = () => {
        setCv(undefined);
    };

    return (
        <AttachCvWrapper>
            {cv ? (
                <Detach>
                    <CvName>{cv.name}</CvName>
                    <DetachButton
                        aria-label='cancel CV upload'
                        onClick={handleCvDetach}
                    >
                        <DetachCVIcon fontSize='small'/>
                    </DetachButton>
                </Detach>
            ) : (
                <>
                    <AttachLabel htmlFor={inputId}>
                        <AttachCVIcon fontSize='small' color='action' />
                        <LabelText>Attach CV</LabelText>
                    </AttachLabel>
                    <Input
                        inputProps={{
                            accept: acceptMimeTypes
                        }}
                        type='file'
                        id={inputId}
                        onChange={handleFileSelect}
                        className={classes.hiddenFileInput}
                    />
                </>
            )}
        </AttachCvWrapper>
    );
};
