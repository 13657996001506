import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
`;

export const PrivacyPolicyLink = () =>
    <Wrapper>
        <a href='https://www.vibeteaching.co.uk/privacy-policy/'>privacy policy</a>
    </Wrapper>
